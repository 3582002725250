import React from 'react';
import Page from 'components/page/page';
import PageHeaderNotFound from 'components/page-header/page-header-not-found';
import PageSection from 'components/page/page-section';
import ErrorBlock from 'components/error-block/error-block';
import { Lexeme } from '@bumble/localization/components';
import Link from 'components/link/link';
import { routes } from 'lib/constants-team-bumble';

export default function NotFoundPage(props: any) {
    return (
        <Page
            theme={props.theme}
            header={<PageHeaderNotFound menuAction={false} />}
            content={
                <PageSection inner={true}>
                    <ErrorBlock
                        code={props.code || 404}
                        title={<Lexeme path="bma_client@video_chat.error_title" />}
                        description={
                            <>
                                <Lexeme path="bma_client@pages.error.not-found.description" />
                                <p>
                                    <Link
                                        className="link"
                                        href={routes.index}
                                        aria-label="Home page"
                                    >
                                        <Lexeme path="onboarding@bumble.incognito.dismiss_warning.cta.cancel" />
                                    </Link>
                                </p>
                            </>
                        }
                    />
                </PageSection>
            }
            contentCenter={true}
        />
    );
}

NotFoundPage.Theme = Page.Theme;
