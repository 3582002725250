import React, { useCallback } from 'react';
import Header from 'components/header/header';
import Button from 'components/button/button';
import Logo from 'components/logo/logo';
import Icon from 'components/icon/icon';
import { Lexeme, useLocalization } from '@bumble/localization/components';

type Props = {
    menuAction?: boolean;
};

export default function PageHeaderNotFound({ menuAction = true }: Props) {
    const onOpenMenu = useCallback(() => {
        global.document.body.classList.toggle('is-opened-mobile-nav', true);
        // This is super crappy but need to leave it as is for now
        const selector = global.document.querySelector('.js-mobile-navigation');

        if (selector) {
            selector.classList.toggle('is-active', true);
        }
    }, []);

    const Localization = useLocalization();

    return (
        <Header
            logo={<Logo />}
            actionMobilePanel={
                menuAction ? (
                    //@todo - https://jira.badoojira.com/browse/LOC-5433
                    <Button
                        labelText="Open Menu"
                        isCircle={true}
                        icon={<Icon size="md" name={Icon.Name.Menu} />}
                        onClick={onOpenMenu}
                    />
                ) : null
            }
            actionAppDownload={
                <Button
                    labelText={Localization.get(
                        'bma_client@dialog.photo_verification.not_access.download_app'
                    )}
                    color={Button.color.Primary}
                    href="https://bumble.com/#download-app"
                    text={
                        <Lexeme path="bma_client@dialog.photo_verification.not_access.download_app" />
                    }
                />
            }
        />
    );
}
