import * as React from 'react';
import NotFoundPageComponent from 'components/not-found-page';
import Head from 'next/head';

function NotFoundPage() {
    return (
        <>
            <Head>
                <link
                    id="deferred-styles"
                    rel="preload"
                    href="/fonts/Circular20/fonts-circular20.css"
                    as="style"
                />
            </Head>
            <NotFoundPageComponent code={404} />
        </>
    );
}

export default NotFoundPage;
